<template>
  <div class="google-signin row center-xs middle-xs" @click="doLogin">
    <div class="google-logo">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="18px"
        height="18px"
        viewBox="0 0 48 48"
        class="google-g"
      >
        <g>
          <path
            fill="#EA4335"
            d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
          />
          <path
            fill="#4285F4"
            d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
          />
          <path
            fill="#FBBC05"
            d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
          />
          <path
            fill="#34A853"
            d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
          />
          <path fill="none" d="M0 0h48v48H0z" />
        </g>
      </svg>
    </div>
    <span class="signin">Sign in with Google</span>
  </div>
</template>

<script>
import { isBlank } from 'adready-api/helpers/common';

export default {
  name: 'GoogleSignInButton',
  props: {
    oAuthUrlHost: {
      required: false,
      type: String,
      default: '',
    },
  },
  methods: {
    doLogin() {
      this.$emit('click');
      let url = '/oauth2/authorization/google';
      if (!isBlank(this.oAuthUrlHost)) {
        url = `${this.oAuthUrlHost}${url}`;
      }
      window.location = url;
    },
  },
};
</script>

<style lang="scss" scoped>
.google-signin {
  box-sizing: border-box;
  display: inline-flex;
  align-items: baseline;
  width: 100%;
  max-width: 100%;
  height: 40px !important;
  padding: 0px 8px;
  line-height: 40px !important;
  color: rgb(66, 82, 110) !important;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  cursor: default;
  background: rgb(255, 255, 255) none repeat scroll 0% 0% !important;
  border-width: 0px;
  border-radius: 3px;
  outline: currentcolor none medium !important;
  box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 5px 0px !important;
  transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;

  &:hover {
    cursor: pointer;

    .google-logo {
      animation-name: pop;
      animation-duration: 0.3s;
      animation-timing-function: linear;
      animation-iteration-count: 1;
    }
  }

  .google-logo {
    display: inline-block;
    padding: 0 15px 0 0;
    margin: 0;
    vertical-align: middle;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    transform: perspective(1px) translateZ(0);

    svg {
      margin: 0;
    }
  }
  .signin {
    font-family: Roboto, arial, sans-serif;
    font-size: 16px;
  }
}

@keyframes pop {
  50% {
    transform: scale(1.2);
  }
}
</style>
