/**
 * Page theme (CSS) switching helpers.
 */

import $ from 'jquery';
import config from '~/config';
import { getQueryParams } from '~/helpers/global/url-helpers';

const layoutHelpers = {
  // Current theme name
  // Changed in updateTheme() below
  currentTheme: 'default',
  themes: undefined,

  detectTheme() {
    const params = getQueryParams(window.location.href);
    if (params) {
      if (params.app === '5') {
        window.document.title = config.APP_NAME || 'Reporting';
        if (params.mode === 'fp') {
          return 'theme-flipplus';
        }
      } else if (params.app === '1') {
        window.document.title = 'AdReady';
      }
    }
    return 'theme-default';
  },

  /**
   * Load the list of themes from the <link> tags in the html
   *
   * This is a workaround for the default way of loading themes provided by the
   * theme-switch package, because the value returned by `process.themes` was
   * storing the incorrect hash for the CSS files in the final package.
   * Not entirely clear why this is, but the upstream sample uses the `webpack`
   * tool to build the app instead of `vue-cli build`. There is probably some
   * difference in the two.
   */
  loadThemes() {
    const themes = {};
    $('link').each((i, el) => {
      // <link href="/static/css/theme-default.27d90b5e.css" rel="preload" as="style">
      const href = $(el).attr('href');
      if (!href) {
        return;
      }
      const m = href.match(/\/(theme-[a-z]+)\..*\.css$/);
      if (!m) {
        return;
      }
      themes[m[1]] = href;
    });
    return themes;
  },

  /**
   * Update theme based on current domain
   *
   * @param {String} val
   */
  updateTheme(val) {
    if (this.themes === undefined) {
      this.themes = this.loadThemes();
    }
    if (!val) {
      val = this.detectTheme();
    }

    // swap class on body and load css file
    $('body')
      .removeClass(this.currentTheme)
      .addClass(val);
    this.currentTheme = val;
  },
};

export default layoutHelpers;
