/**
 * Map of promises by key
 */
const caches = {};

/**
 * Dedupe the given block of code by returning an existing promise for an
 * in-flight operation, thus saving repeated calls to `store.set` for every
 * plan/line item and the resulting reactive events.
 *
 * This is the same technique we use in the `api-dupe-request-plugin` which
 * dedupes concurrent calls to the same AXIOS endpoint, however we will still
 * have a race in the loaders as different instances of the same component
 * repeatedly try to fetch the same data on page load (or tab/account switch).
 *
 * @param {String} key Cache key
 * @param {Function} fn Data loading function
 */
export async function dedupe(key, fn) {
  if (caches[key]) {
    return caches[key];
  }

  const promise = fn().then((val) => {
    // delete cached promise & return the val
    delete caches[key];
    return val;
  });

  caches[key] = promise;
  return promise;
}
