import { ApiCrud } from 'adready-api/api/api-crud';
import http from 'adready-api/api/http';

const advertiserReportsApi = new ApiCrud(http.AXIOS, {
  impressionsSummary: 'POST advertisers/:id/mdsp/impressions/summary',
  conversionsSummary: 'POST advertisers/:id/conversions/summary',
  incremental_reach_metrics: 'advertisers/:id/performance/metrics/incrementalreach',
  liftSummary: 'POST advertisers/:id/lift/summary',
  performance: 'POST advertisers/:id/performance',
  conversionsDaily: 'POST advertisers/:id/conversions/daily',
  impressionsDaily: 'POST advertisers/:id/impressions/daily',
  performanceDaily: 'POST advertisers/:id/performance/daily',
  conversionsDistribution: 'POST advertisers/:id/conversions/distribution',
  mediaTypes: 'POST advertisers/:id/media_types',
  ioNames: 'advertisers/:id/io_names',
  performanceMetricsReach: 'POST advertisers/:id/performance/metrics/reach',
  performanceMetricsAcquisitionCost: 'advertisers/:id/performance/metrics/acquisition_cost',
  ioPacing: 'POST advertisers/:id/io_pacing',
  brandLiftDistribution: 'advertisers/:id/brand_lift/distribution',
  brandLiftTypes: 'advertisers/:id/brand_lift/types',
  brandLiftGroupBy: 'advertisers/:id/brand_lift/groupBy',
  fetchBrandLiftCampaigns: 'advertisers/:id/brand_lift/campaigns',
  fetchBrandLiftCntryLang: 'advertisers/:id/brand_lift/country_languages',
  fetchBrandLiftIndustries: 'advertisers/:id/brand_lift/industries',
  fetchBrandLiftTemplateQuestions: 'advertisers/:id/brand_lift/template_questions',
  createBrandLiftCampaign:
    'POST advertisers/:id/brand_lift/campaigns?client=:client&advertiser=:advertiser&intCampaignId=:ioId&io=:io',
  editBrandLiftCampaign: 'PUT advertisers/:id/brand_lift/campaigns/:campaignId',
  launchBrandLiftCampaign: 'PUT advertisers/:id/brand_lift/campaigns/:campaignId/launch',
  events: 'advertisers/:id/events',
  incrementalityDistribution: 'POST advertisers/:id/incrementality/distribution',
  incrementalityHaloEffect: 'advertisers/:id/incrementality/haloEffect',
  deleteBrandLiftCampaign: 'DELETE advertisers/:id/brand_lift/campaigns/:campaignId',
  fetchBrandLiftCampaignsStat: 'advertisers/:id/brand_lift/campaignsStats',
  adGroupOptions: 'ios/:id/lineItemNamesData',
  reportsRollup: 'advertisers/:id/reports/rollup',
  reportsRollupTouches: 'advertisers/:id/reports/rollup/touches',
  reportsPerformance: 'POST advertisers/:id/reports/performance',
  reportsEventPerformance: 'advertisers/:id/reports/eventPerformance',
  audiences: 'POST  advertisers/:id/audiences',
  creatives: 'POST advertisers/:id/creatives',
  publishers: 'advertisers/:id/publishers',
  isNameExist: 'GET accounts/nameExist',
  isAbbreviationExist: 'GET accounts/abbreviationExist',
  isPrefixExist: 'GET accounts/prefixExist',
  fetchBrandDimensionReport: '/ac/advertisers/:id/solutions/:solutionId/brand/reports',
  mdspImpressionsDaily: 'POST advertisers/:id/mdsp/impressions/daily',
  mdspPerformanceDaily: 'POST advertisers/:id/mdsp/performance/daily',
  impressionsDistribution: 'POST advertisers/:id/mdsp/impressions/distribution',
  adGroupOptionsBQ: 'ios/:id/msdp/lineItemNamesData',
});

export default advertiserReportsApi;
